import cn from 'classnames'

import LogoMiniIconSvg from '../../../assets/icons/logo/logo-mini.svg'
import LogoIconSvg from '../../../assets/icons/logo/logo.svg'
import Seller24Svg from '../../../assets/icons/logo/Seller24.svg'
import Seller24BlackSvg from '../../../assets/icons/logo/Seller24Black.svg'
import MenuIconSvg from '../../../assets/icons/menuIcons/menu.svg'
import MenuAdsIconSvg from '../../../assets/icons/menuIcons/menu_ads.svg'
import MenuDashboardIconSvg from '../../../assets/icons/menuIcons/menu_dashboard.svg'
import MenuEducationIconSvg from '../../../assets/icons/menuIcons/menu_education.svg'
import MenuEventsIconSvg from '../../../assets/icons/menuIcons/menu_events.svg'
import MenuImagesIconSvg from '../../../assets/icons/menuIcons/menu_images.svg'
import MenuIntegrationIconSvg from '../../../assets/icons/menuIcons/menu_integration.svg'
import MenuMainIconSvg from '../../../assets/icons/menuIcons/menu_main.svg'
import MenuMarketAnalyticsIconSvg from '../../../assets/icons/menuIcons/menu_marketAnalytics.svg'
import MenuMarketplacesIconSvg from '../../../assets/icons/menuIcons/menu_marketplaces.svg'
import MenuMonitoringIconSvg from '../../../assets/icons/menuIcons/menu_monitoring.svg'
import MenuOrdersIconSvg from '../../../assets/icons/menuIcons/menu_orders.svg'
import MenuProductsIconSvg from '../../../assets/icons/menuIcons/menu_products.svg'
import MenuReportsIconSvg from '../../../assets/icons/menuIcons/menu_reports.svg'
import MenuReviewsIconSvg from '../../../assets/icons/menuIcons/menu_reviews.svg'
import MenuRichcontentIconSvg from '../../../assets/icons/menuIcons/menu_richcontent.svg'
import MenuRichcontent2IconSvg from '../../../assets/icons/menuIcons/menu_richcontent2.svg'
import MenuSearchIconSvg from '../../../assets/icons/menuIcons/menu_search.svg'
import MenuSettingsIconSvg from '../../../assets/icons/menuIcons/menu_settings.svg'
import MenuSupportIconSvg from '../../../assets/icons/menuIcons/menu_support.svg'
import MenuTasksIconSvg from '../../../assets/icons/menuIcons/menu_tasks.svg'
import MenuTelegramIconSvg from '../../../assets/icons/menuIcons/menu_telegram.svg'
import MenuYoutubeIconSvg from '../../../assets/icons/menuIcons/menu_youtube.svg'
import AscIconSvg from '../../../assets/icons/outline/asc.svg'
import AttentionIconSvg from '../../../assets/icons/outline/attention.svg'
import ChevronUpDownIconSvg from '../../../assets/icons/outline/chevronUpDown.svg'
import DescIconSvg from '../../../assets/icons/outline/desc.svg'
import QtyIconSvg from '../../../assets/icons/outline/qty.svg'
import RateIconSvg from '../../../assets/icons/outline/rate.svg'
import OutlineRubIconSvg from '../../../assets/icons/outline/rub.svg'
import TimeLoaderIconSvg from '../../../assets/icons/outline/time-loader.svg'
import VideoCamIconSvg from '../../../assets/icons/outline/videocam.svg'
import CopyOrderSvg from '../../../assets/icons/seller24Svg/copy-order.svg'
import ThinRubIconSvg from '../../../assets/icons/thin/rub.svg'
import ThinRubRoundIconSvg from '../../../assets/icons/thin/rubRound.svg'

import WhatsappSvg from '../../../assets/icons/outline/whatsapp.svg'
import BarcodeSvg from '../../../assets/icons/seller24Svg/barcode.svg'
import DashboardStubSvg from '../../../assets/icons/seller24Svg/dashboard-stub.svg'
import EmptyOrdersSvg from '../../../assets/icons/seller24Svg/empty-orders.svg'
import LineSvg from '../../../assets/icons/seller24Svg/line.svg'
import NoAvatarSvg from '../../../assets/icons/seller24Svg/no-avatar.svg'
import PlaySvg from '../../../assets/icons/seller24Svg/play.svg'
import ProductIconSvg from '../../../assets/icons/seller24Svg/product-icon.svg'
import RegionSaleStubSvg from '../../../assets/images/regionSaleStub.svg'

function makeIconComponent(
    IconComponent,
    displayName = '',
    extraClassName = ''
) {
    const Component = ({className = ''}) => (
        <IconComponent className={cn('svg-icon', className, extraClassName)} />
    )
    Component.displayName = displayName
    return Component
}

export const MenuIcon = makeIconComponent(MenuIconSvg, 'MenuIcon')
export const MenuAdsIcon = makeIconComponent(MenuAdsIconSvg, 'MenuAdsIconSvg')
export const MenuDashboardIcon = makeIconComponent(
    MenuDashboardIconSvg,
    'MenuDashboardIconSvg'
)
export const MenuEducationIcon = makeIconComponent(
    MenuEducationIconSvg,
    'MenuEducationIconSvg'
)
export const MenuEventsIcon = makeIconComponent(
    MenuEventsIconSvg,
    'MenuEventsIconSvg'
)
export const MenuImagesIcon = makeIconComponent(
    MenuImagesIconSvg,
    'MenuImagesIconSvg'
)
export const MenuIntegrationIcon = makeIconComponent(
    MenuIntegrationIconSvg,
    'MenuIntegrationIconSvg'
)
export const MenuMarketplacesIcon = makeIconComponent(
    MenuMarketplacesIconSvg,
    'MenuMarketplacesIconSvg'
)
export const MenuMarketAnalyticsIcon = makeIconComponent(
    MenuMarketAnalyticsIconSvg,
    'MenuMarketAnalyticsIconSvg'
)
export const MenuMainIcon = makeIconComponent(
    MenuMainIconSvg,
    'MenuMainIconSvg'
)
export const MenuMonitoringIcon = makeIconComponent(
    MenuMonitoringIconSvg,
    'MenuMonitoringIconSvg'
)
export const MenuOrdersIcon = makeIconComponent(
    MenuOrdersIconSvg,
    'MenuOrdersIconSvg'
)
export const MenuProductsIcon = makeIconComponent(
    MenuProductsIconSvg,
    'MenuProductsIconSvg'
)
export const MenuReportsIcon = makeIconComponent(
    MenuReportsIconSvg,
    'MenuReportsIconSvg'
)
export const MenuReviewsIcon = makeIconComponent(
    MenuReviewsIconSvg,
    'MenuReviewsIconSvg'
)
export const MenuRichcontentIcon = makeIconComponent(
    MenuRichcontentIconSvg,
    'MenuRichcontentIconSvg'
)
export const MenuRichcontent2Icon = makeIconComponent(
    MenuRichcontent2IconSvg,
    'MenuRichcontent2IconSvg'
)
export const MenuSearchIcon = makeIconComponent(
    MenuSearchIconSvg,
    'MenuSearchIconSvg'
)
export const MenuSettingsIcon = makeIconComponent(
    MenuSettingsIconSvg,
    'MenuSettingsIconSvg'
)
export const MenuSupportIcon = makeIconComponent(
    MenuSupportIconSvg,
    'MenuSupportIconSvg'
)
export const MenuTasksIcon = makeIconComponent(
    MenuTasksIconSvg,
    'MenuTasksIconSvg'
)
export const MenuTelegramIcon = makeIconComponent(
    MenuTelegramIconSvg,
    'MenuTelegramIconSvg'
)
export const MenuYoutubeIcon = makeIconComponent(
    MenuYoutubeIconSvg,
    'MenuYoutubeIconSvg'
)

export const LogoIcon = makeIconComponent(LogoIconSvg, 'LogoIconSvg')
export const LogoMiniIcon = makeIconComponent(
    LogoMiniIconSvg,
    'LogoIconMiniSvg'
)

export const Seller24Icon = makeIconComponent(Seller24Svg, 'Seller24Svg')
export const Seller24BlackIcon = makeIconComponent(
    Seller24BlackSvg,
    'Seller24BlackSvg'
)

export const ThinRubIcon = makeIconComponent(ThinRubIconSvg, 'ThinRubIconSvg')

export const ThinRubRoundIcon = makeIconComponent(
    ThinRubRoundIconSvg,
    'ThinRubRoundIconSvg'
)

export const OutlineRubIcon = makeIconComponent(
    OutlineRubIconSvg,
    'OutlineRubIconSvg'
)

export const ChevronUpDownIcon = makeIconComponent(
    ChevronUpDownIconSvg,
    'ChevronUpDownIconSvg'
)

export const AscIcon = makeIconComponent(AscIconSvg, 'AscIconSvg')

export const DescIcon = makeIconComponent(DescIconSvg, 'DescIconSvg')

export const RateIcon = makeIconComponent(RateIconSvg, 'RateIconSvg')

export const QtyIcon = makeIconComponent(QtyIconSvg, 'QtyIconSvg')

export const TimeLoaderIcon = makeIconComponent(
    TimeLoaderIconSvg,
    'TimeLoaderIconSvg'
)

export const ProductIcon = makeIconComponent(ProductIconSvg, 'ProductIcon')
export const CopyOrderIcon = makeIconComponent(CopyOrderSvg, 'CopyOrderIcon')
export const EmptyOrdersIcon = makeIconComponent(
    EmptyOrdersSvg,
    'EmptyOrdersIcon'
)
export const LineIcon = makeIconComponent(LineSvg, 'LineIcon')
export const BarcodeIcon = makeIconComponent(BarcodeSvg, 'BarcodeIcon')
export const DashboardStubIcon = makeIconComponent(
    DashboardStubSvg,
    'dashboardStub'
)
export const NoAvatarIcon = makeIconComponent(NoAvatarSvg, 'NoAvatarIcon')
export const AttentionIcon = makeIconComponent(
    AttentionIconSvg,
    'AttentionIcon'
)

export const WhatsappIcon = makeIconComponent(WhatsappSvg, 'WhatsappIcon')
export const PlayIcon = makeIconComponent(PlaySvg, 'PlayIcon')
export const RegionSaleStub = makeIconComponent(
    RegionSaleStubSvg,
    'RegionSaleStub'
)
export const VideoCamIcon = makeIconComponent(VideoCamIconSvg, 'VideoCamIcon')
