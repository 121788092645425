import {dynamicRoutes} from '@utils/dynamicImport/dynamicRoutes'
import {lazy} from 'react'

export const dynamicImport = (key) => {
    return lazy(() =>
        dynamicRoutes[key]().catch((e) => {
            console.log(e)
            if (
                e.message.includes(
                    'dynamically imported module' &&
                        import.meta.env.MODE === 'production'
                )
            ) {
                console.log(
                    'Обнаружена ошибка чанка! Перезапускаем страницу...'
                )
                window.location.reload()
            }
        })
    )
}
