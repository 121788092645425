import {Select} from '@components/forms/Selects'
import {observer} from 'mobx-react'

import {MarketListItem} from '@components/applicationUi'
import {TextField} from '@components/forms/TextFields'

export const ImportWbFilters = observer(({filtersStore}) => {
    const handleChangeLegalEntity = (e) => {
        filtersStore.setLegalEntity(e.target.value)
    }
    return (
        <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
            <Select
                label={'Кабинет'}
                selected={filtersStore.cabinet.selected}
                options={filtersStore.cabinet.options}
                onChange={filtersStore.cabinet.setSelectedItem}
            >
                {({option, selected, active}) => (
                    <MarketListItem name={option.name} market={option.market} />
                )}
            </Select>
            <TextField
                label={'Юр.лицо'}
                disabled={filtersStore.validLegalEntity}
                error={filtersStore.isLegalEntityError}
                helperText={
                    filtersStore.isLegalEntityError && 'Заполните Юр.лицо'
                }
                value={
                    filtersStore.validLegalEntity
                        ? filtersStore.savedLegalEntity
                        : filtersStore.legalEntity
                }
                onChange={handleChangeLegalEntity}
            />
        </div>
    )
})

ImportWbFilters.displayName = 'ImportWbFilters'
