import {Card} from '@components/layout'
import {observer} from 'mobx-react'

import {InfoField, MarketListItem} from '@components/applicationUi'
import {SettingsSelect} from '@components/applicationUi/SettingsSelect'

export const ImportWbInfoFilters = observer(({filterStore}) => {
    return (
        <Card innerClassName={'space-y-2'}>
            <h2
                className={
                    'text-base font-medium text-gray-800 dark:text-gray-200'
                }
            >
                Отбор отчетов
            </h2>
            <SettingsSelect
                label={'Кабинет'}
                selected={filterStore.cabinet.selected}
                options={filterStore.cabinet.options}
                onChange={filterStore.cabinet.setSelectedItem}
            >
                {({option, selected, active}) => (
                    <MarketListItem name={option.name} market={option.market} />
                )}
            </SettingsSelect>
            <InfoField
                label={'Юр.лицо'}
                helpContent={
                    'Если вы хотите изменить юр.лицо или привязать текущее юр.лицо к другому кабинету - обратитесь в тех. поддержку.'
                }
            >
                <span className={'text-sm'}>
                    {filterStore.cabinetCompanyName
                        ? filterStore.cabinetCompanyName
                        : ''}
                </span>
            </InfoField>
        </Card>
    )
})

ImportWbInfoFilters.displayName = 'ImportWbInfoFilters'
