import {MarketIcon} from '@components/elements'

export const MarketListItem = ({market, name}) => {
    return (
        <div className={'flex items-center'}>
            <MarketIcon
                iconClassName={'w-4x5 h-4x5'}
                size={20}
                name={market}
                className={'mr-3 flex-shrink-0 self-start'}
            />
            <span className={''}>{name}</span>
        </div>
    )
}
